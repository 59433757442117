import React, { useState, useEffect } from "react";
import { fetchReferralStats } from "../../service";
import { HttpStatus } from "../../../utils/constants";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import {
  Referral,
  formatDate,
} from "../../../Referrals/component/ReferralsTable";
import "./style.scss";
import {
  DAYTIME_PHASE,
  defaultPageSize,
  EMPTY_ERROR,
} from "../../../common/constants";
import { getTimeStampedDate } from "../../../teamslist/component/TeamConfigure/teamConfigHelper";

export const ReferralStatComponent = ({ startDate, endDate }) => {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [referralStats, setReferralStats] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });

  useEffect(() => {
    getReferralStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, currentPage, pageSize]);

  const getReferralStats = async () => {
    setReferralStats({
      ...referralStats,
      isLoading: true,
      error: EMPTY_ERROR,
    });
    const tsStartDate = getTimeStampedDate(startDate, DAYTIME_PHASE.START);
    const tsEndDate = getTimeStampedDate(endDate, DAYTIME_PHASE.END);
    const resData: any = await fetchReferralStats({
      startDate: tsStartDate,
      endDate: tsEndDate,
      skip: (currentPage - 1) * pageSize,
      limit: pageSize,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setReferralStats((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setReferralStats({
        ...referralStats,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
    }
  };

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "code",
        id: "code",
        header: () => <span>{Referral.code}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "isDeleted",
        id: "status",
        header: () => <span>{Referral.status}</span>,
        cell: (info) => (
          <span
            style={{
              color: info.getValue() ? "red" : "green",
            }}
          >
            {info.getValue() ? "Deactivated" : "Active"}
          </span>
        ),
      },
      {
        accessorKey: "createdAt",
        id: "createdAt",
        header: () => <span>{Referral.createdAt}</span>,
        cell: (info) => formatDate(info.getValue()),
      },
      {
        accessorKey: "count",
        id: "count",
        header: () => <span>{Referral.count}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "userCreatedTeams",
        id: "userCreatedTeams",
        header: () => <span>{Referral.createdUserTeams}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "redeemCount",
        id: "redeemCount",
        header: () => <span>{Referral.bonusRedeemed}</span>,
        cell: (info) => info.getValue(),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <TableWrapper>
        <ReactTable
          isLoading={referralStats?.isLoading}
          data={referralStats?.data?.promotions || []}
          columns={columns}
          pageChangeHandler={setCurrentPage}
          pageSizeHandler={setPageSize}
          currentPageNumber={currentPage}
          totalRows={referralStats?.data?.totalCount}
          rowsPerPage={pageSize}
          hasError={referralStats?.error.isError}
          errorMessage={referralStats?.error.errorMsg}
        />
      </TableWrapper>
    </>
  );
};

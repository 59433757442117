import {
  useState,
  useEffect,
  Suspense,
  lazy,
  useCallback,
  useMemo,
} from "react";
import { Button, Tabs, Tab, CardBody, Card } from "react-bootstrap";
import "./style.scss";
import { fetchMatchConfig } from "../../service";
import { HttpStatus } from "../../../utils/constants";
import TeamConfigInfo from "./TeamConfigInFo";
import PlayersConfig from "../PlayersConfig";
import {
  Action,
  ContestTypeEnum,
  EMPTY_ERROR,
  Match,
} from "../../../common/constants";
import { getFutyStar } from "./teamConfigHelper";
import ReactToastr from "../../../common/components/ReactToaster";
import { MatchPushNotification } from "./MatchPushNotification";
import Typography from "../../../common/components/Typography";
import { TeamConfigButtons } from "./TeamConfigButtons";
import PrizeConfig from "../../../common/components/PrizeConfig";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const TranserPlayers = lazy(() => import("./Transfer"));

const TeamConfigure = () => {
  const [saveLoader, setSaveLoader] = useState(false);
  const [publishLoader, setPublishLoader] = useState(false);
  const [team1Color, setTeam1Color] = useState<any>({});
  const [team2Color, setTeam2Color] = useState<any>({});
  const [team1ColorOptions, setTeam1ColorOptions] = useState([]);
  const [team2ColorOptions, setTeam2ColorOptions] = useState([]);
  const [matchConfig, setMatchConfig] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [activeTab, setActiveTab] = useState<any>(1);
  const [prizeList, setPrizeList] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [futyStar, setFutyStar] = useState([]);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callBack: () => {},
  });
  const { matchId } = useParams();
  const location = useLocation();
  const isTgMatches = useMemo(
    () => location.pathname.includes("telegram"),
    [location.pathname],
  );
  const navigate = useNavigate();

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));

    if (showToaster?.callBack) {
      showToaster.callBack();
    }
  };
  const getMatchConfig = useCallback(async () => {
    setMatchConfig((pre) => ({
      ...pre,
      isLoading: true,
      error: EMPTY_ERROR,
    }));
    const resData: any = await fetchMatchConfig(
      {},
      {
        matchId: matchId,
        contestType: isTgMatches
          ? ContestTypeEnum.TG_FANTASY
          : ContestTypeEnum.MATCH,
      },
    );
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setMatchConfig((pre) => ({
        ...pre,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setMatchConfig((pre) => ({
        ...pre,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      }));
    }
  }, [matchId, isTgMatches]);

  useEffect(() => {
    if (matchId) getMatchConfig();
  }, [matchId, getMatchConfig]);

  useEffect(() => {
    if (matchConfig?.data) {
      const team1Color = matchConfig?.data?.matchData?.teamId1?.teamColor;
      const team2Color = matchConfig?.data?.matchData?.teamId2?.teamColor;
      const isTeam1ColorExist =
        matchConfig?.data?.matchData?.teamId1?.teamColors?.find(
          (item) => item === team1Color,
        );
      const isTeam2ColorExist =
        matchConfig?.data?.matchData?.teamId2?.teamColors?.find(
          (item) => item === team2Color,
        );
      setPrizeList(matchConfig?.data?.matchData?.prize || []);
      setPlayerList(matchConfig?.data?.players || []);
      setFutyStar(getFutyStar(matchConfig?.data?.players || []));
      setTeam1ColorOptions(
        matchConfig?.data?.matchData?.teamId1?.teamColors?.map((color) => ({
          label: color,
          value: color,
        })),
      );
      setTeam2ColorOptions(
        matchConfig?.data?.matchData?.teamId2?.teamColors?.map((color) => ({
          label: color,
          value: color,
        })),
      );
      setTeam1Color(
        isTeam1ColorExist ? { label: team1Color, value: team1Color } : null,
      );
      setTeam2Color(
        isTeam2ColorExist ? { label: team2Color, value: team2Color } : null,
      );
    }
  }, [matchConfig]);

  const isPubishedMatch = matchConfig?.data?.matchData?.published
    ? true
    : false;

  return (
    <>
      <div className="team-configure-wrapper">
        <div className="go-back-wrapper">
          <Button
            size="sm"
            variant="link"
            onClick={() => {
              navigate(-1);
            }}
          >
            {Action.goBack}
          </Button>
          <Typography variant="heading4">{`${Match.matchId} ${
            matchId ?? ""
          }`}</Typography>
        </div>
        <Card className="bg-white">
          <CardBody>
            <TeamConfigInfo
              match={matchConfig?.data?.matchData}
              matchMetaData={{
                isLoading: matchConfig?.isLoading,
                error: matchConfig?.error,
              }}
              team1Color={team1Color}
              team2Color={team2Color}
              setTeam1Color={setTeam1Color}
              setTeam2Color={setTeam2Color}
              team1ColorOptions={team1ColorOptions}
              team2ColorOptions={team2ColorOptions}
              isReadOnly={isPubishedMatch || saveLoader || publishLoader}
            />
          </CardBody>
        </Card>
        <div className="tab-wrapper teamConfig-tab-wrapper">
          <Tabs
            defaultActiveKey={1}
            id="matchConfigTab"
            className="mb-3 match-config-tabs"
            activeKey={activeTab}
            onSelect={(key: any) => setActiveTab(key)}
          >
            <Tab
              eventKey={1}
              tabClassName="matchConfig-tab-content"
              title={"Players"}
            >
              <PlayersConfig
                matchMetaData={{
                  isLoading: matchConfig?.isLoading,
                  error: matchConfig?.error,
                }}
                playerList={playerList}
                matchData={matchConfig?.data}
                futyStar={futyStar}
                setFutyStar={setFutyStar}
                isReadOnly={isPubishedMatch}
              />
            </Tab>
            <Tab
              eventKey={2}
              tabClassName="matchConfig-tab-content"
              title={"Prize"}
            >
              <PrizeConfig
                isLoading={matchConfig?.isLoading}
                isError={matchConfig?.error?.isError}
                errorMsg={matchConfig?.error?.errorMsg}
                prizeList={prizeList}
                setPrizeList={setPrizeList}
                isReadOnly={isPubishedMatch}
                setShowToaster={setShowToaster}
                isDisabledDeleteRow={isPubishedMatch}
              />
            </Tab>
            {isPubishedMatch && (
              <Tab
                eventKey={3}
                tabClassName="matchConfig-tab-content"
                title={"Notification"}
              >
                <MatchPushNotification matchId={matchId} />
              </Tab>
            )}
            {isPubishedMatch && (
              <Tab
                eventKey={4}
                tabClassName="matchConfig-tab-content"
                title={Match.published.transferTitle}
              >
                {activeTab === "4" && (
                  <Suspense fallback={null}>
                    <TranserPlayers
                      matchId={matchId}
                      matchData={matchConfig?.data}
                      setShowToaster={setShowToaster}
                      getMatchConfig={getMatchConfig}
                    />
                  </Suspense>
                )}
              </Tab>
            )}
          </Tabs>
        </div>
        {!matchConfig.isLoading && !matchConfig?.error?.isError && (
          <TeamConfigButtons
            match={matchConfig?.data?.matchData}
            isPubishedMatch={isPubishedMatch}
            saveLoader={saveLoader}
            publishLoader={publishLoader}
            setSaveLoader={setSaveLoader}
            setPublishLoader={setPublishLoader}
            prizeList={prizeList}
            setShowToaster={setShowToaster}
            team1Color={team1Color}
            team2Color={team2Color}
            futyStar={futyStar}
            playerList={playerList}
          />
        )}
      </div>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};

export default TeamConfigure;

import { Button, Col, Row } from "react-bootstrap";
import Typography from "../../../common/components/Typography";
import { Form as BsForm } from "react-bootstrap";
import { Field, FieldArray } from "formik";
import Select from "react-select";
import {
  currencyOptions,
  GameModesEnum,
  gameModesOptions,
  iconRedirectionUrlMap,
  MatchStatus,
  Option,
  RewardCurrencyEnum,
  taskIcons,
  taskLogoMap,
  trackingTypeOptions,
  TrackingTypesEnum,
} from "../../tasksUtils";
import { useEffect, useState } from "react";
import {
  fetchFantasyMatches,
  fetchGoalfestGameweeks,
  fetchQuizMatches,
} from "../../service";

const customOptions = (option) => (
  <div className="d-flex align-items-center">
    <img
      src={taskLogoMap[option.value]}
      alt={option.label}
      style={{ width: 20, height: 20, marginRight: 10 }}
    />
    {option.label}
  </div>
);

const TaskFields = ({
  idx,
  task,
  setFieldValue,
  errors,
  values,
  touched,
  setFieldError,
}) => {
  const gameModes: GameModesEnum[] = values.tasks[idx].gameModes;
  const [matchOptions, setMatchOptions] = useState<Option[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setMatchOptions([]);
      const fetchPromises = [];

      if (gameModes?.includes(GameModesEnum.TG_TRIPLE_THREAT)) {
        fetchPromises.push(
          fetchQuizMatches({
            isPublished: true,
            status: [MatchStatus.SCHEDULED],
          }),
        );
      }

      if (gameModes?.includes(GameModesEnum.TG_GOALFEST)) {
        fetchPromises.push(
          fetchGoalfestGameweeks({
            query: {
              isPublished: true,
              status: [MatchStatus.SCHEDULED],
            },
          }),
        );
      }

      if (gameModes?.includes(GameModesEnum.TG_FANTASY)) {
        fetchPromises.push(
          fetchFantasyMatches({
            query: {
              isPublished: true,
              status: [MatchStatus.SCHEDULED],
            },
          }),
        );
      }

      const results = await Promise.all(fetchPromises);
      setMatchOptions(results.flat());
    };

    fetchData();
  }, [gameModes]);

  return (
    <section className="border p-2 rounded mb-3">
      <Typography variant="heading3">Tier {idx + 1}</Typography>
      <Row>
        <Col md={4}>
          <BsForm.Label>Task Title</BsForm.Label>
          <Field
            type="text"
            name={`tasks.${idx}.title`}
            className="form-control"
          />
          {errors.tasks?.at(idx)?.title && touched.tasks?.at(idx)?.title ? (
            <Typography className="text-danger fs-10">
              {errors.tasks[idx].title}
            </Typography>
          ) : null}
        </Col>
        <Col md={4}>
          <BsForm.Label>Tier Number</BsForm.Label>
          <Field
            type="number"
            name={`tasks.${idx}.tierNumber`}
            className="form-control"
            value={idx + 1}
            disabled={true}
          />
        </Col>
      </Row>

      <Row className="d-flex my-3 align-items-end">
        <Col md={4}>
          <BsForm.Label>Select Icon</BsForm.Label>
          <Select
            name={`tasks.${idx}.iconUrl`}
            options={taskIcons}
            placeholder="Select icon"
            value={taskIcons.find((option) => option.value === task.iconUrl)}
            onChange={(option) => {
              setFieldValue(`tasks.${idx}.iconUrl`, option ? option.value : "");
              setFieldValue(
                `tasks.${idx}.redirectionUrl`,
                iconRedirectionUrlMap[option.label] ?? "",
              );
              setFieldError(`tasks.${idx}.iconUrl`, undefined);
            }}
            formatOptionLabel={customOptions}
          />
          {errors.tasks?.at(idx)?.iconUrl && touched.tasks?.at(idx)?.iconUrl ? (
            <Typography className="text-danger fs-10">
              {errors.tasks[idx].iconUrl}
            </Typography>
          ) : null}
        </Col>
        <Col md={4}>
          <BsForm.Label>Redirection URL</BsForm.Label>
          <Field
            type="text"
            name={`tasks.${idx}.redirectionUrl`}
            className="form-control"
          />
          {errors.tasks?.at(idx)?.redirectionUrl &&
            touched.tasks?.at(idx)?.redirectionUrl && (
              <Typography className="text-danger fs-10">
                {errors.tasks[idx].redirectionUrl}
              </Typography>
            )}
        </Col>
        <Col md={2}>
          <BsForm.Check
            type="checkbox"
            label="isInAppUrl"
            name={`tasks.${idx}.isInAppUrl`}
            checked={task?.isInAppUrl}
            onChange={() =>
              setFieldValue(`tasks.${idx}.isInAppUrl`, !task?.isInAppUrl)
            }
          />
          {errors.tasks?.at(idx).isInAppUrl &&
            touched.tasks?.at(idx).isInAppUrl && (
              <Typography className="text-danger fs-10">
                {errors.tasks?.at(idx)?.isInAppUrl}
              </Typography>
            )}
        </Col>
        <Col md={2}>
          <BsForm.Check
            type="checkbox"
            label="isTrackable"
            name={`tasks.${idx}.isTrackable`}
            checked={task.isTrackable}
            onChange={() =>
              setFieldValue(`tasks.${idx}.isTrackable`, !task.isTrackable)
            }
          />
        </Col>
      </Row>

      <FieldArray name={`tasks.${idx}.rewards`}>
        {({ push, remove }) => (
          <div className="border p-2 rounded my-3">
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={10}>
                <Typography variant="heading4">Rewards Config</Typography>
              </Col>
              <Col>
                <Button
                  variant="primary"
                  onClick={() =>
                    push({
                      amount: 0,
                      currency: RewardCurrencyEnum.FUTY_CREDITS,
                      rewardAfterMins: 0,
                    })
                  }
                >
                  Add Reward
                </Button>
              </Col>
            </Row>
            {task.rewards.map((_, index) => (
              <Row key={index} className="p-3 rounded d-flex align-items-end">
                <BsForm.Label className="text-black">
                  Reward {index + 1}
                </BsForm.Label>

                <Col md={3}>
                  <BsForm.Label>Amount</BsForm.Label>
                  <Field
                    type="number"
                    name={`tasks.${idx}.rewards.${index}.amount`}
                    className="form-control"
                    placeholder="Amount"
                  />
                  {errors.tasks?.at(idx)?.rewards?.at(index)?.amount &&
                    touched.tasks?.at(idx)?.rewards?.at(index)?.amount && (
                      <Typography className="text-danger fs-10">
                        {errors.tasks?.at(idx)?.rewards?.at(index)?.amount}
                      </Typography>
                    )}
                </Col>
                <Col md={3}>
                  <BsForm.Label>Currency</BsForm.Label>
                  <Select
                    options={currencyOptions}
                    name={`tasks.${idx}.rewards.${index}.currency`}
                    className="select-filter border-0"
                    placeholder="Currency"
                    value={currencyOptions.find(
                      (option) =>
                        option.value === task?.rewards?.at(index)?.currency,
                    )}
                    onChange={(selectedOption) => {
                      setFieldValue(
                        `tasks.${idx}.rewards.${index}.currency`,
                        selectedOption ? selectedOption.value : "",
                      );
                      setFieldError(
                        `tasks.${idx}.rewards.${index}.currency`,
                        undefined,
                      );
                    }}
                  />
                  {errors.tasks?.at(idx)?.rewards?.at(index)?.currency &&
                    touched.tasks?.at(idx)?.rewards?.at(index)?.currency && (
                      <Typography className="text-danger fs-10">
                        {errors.tasks?.at(idx)?.rewards?.at(index)?.currency}
                      </Typography>
                    )}
                </Col>
                <Col md={3}>
                  <BsForm.Label>RewaredAfterMins</BsForm.Label>
                  <Field
                    type="number"
                    name={`tasks.${idx}.rewards.${index}.rewardAfterMins`}
                    className="form-control"
                    placeholder="Delay in minutes"
                  />
                </Col>
                {values.tasks.at(idx).rewards.length > 1 && (
                  <Col md={3}>
                    <Button variant="danger" onClick={() => remove(index)}>
                      Remove Reward
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
          </div>
        )}
      </FieldArray>

      <Row className="my-3">
        <Typography variant="heading4">Tracking details</Typography>
        <Col>
          <Select
            name={`tasks.${idx}.trackingDetails.trackingType`}
            placeholder="Select tracking type"
            options={trackingTypeOptions}
            value={trackingTypeOptions.find(
              (option) => option.value === task.trackingDetails.trackingType,
            )}
            onChange={(selectedOption) => {
              setFieldValue(
                `tasks.${idx}.trackingDetails.trackingType`,
                selectedOption ? selectedOption.value : "",
              );
              if (
                selectedOption?.value === TrackingTypesEnum.GAME_PARTICIPATION
              ) {
                setFieldValue(`tasks.${idx}.isInAppUrl`, true);
              } else {
                setFieldValue(`tasks.${idx}.isInAppUrl`, false);
              }
            }}
          />
          {errors.tasks?.at(idx)?.trackingDetails?.trackingType ? (
            <Typography className="text-danger fs-10">
              {errors.tasks?.at(idx)?.trackingDetails?.trackingType}
            </Typography>
          ) : null}
        </Col>
      </Row>
      {(task.isTrackable ||
        task?.trackingDetails?.trackingType ===
          TrackingTypesEnum.GAME_PARTICIPATION) && (
        <Row>
          <Col md={4}>
            <BsForm.Group>
              <Select
                isMulti
                name={`tasks.${idx}.gameModes`}
                placeholder="Select Game modes"
                options={gameModesOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                menuPlacement="top"
                value={gameModesOptions.filter((option) =>
                  task.gameModes?.includes(option.value),
                )}
                onChange={(selectedOptions) =>
                  setFieldValue(
                    `tasks.${idx}.gameModes`,
                    selectedOptions?.length
                      ? selectedOptions.map((option) => option.value)
                      : null,
                  )
                }
              />
              {errors.tasks?.at(idx)?.gameModes &&
                touched.tasks?.at(idx)?.gameModes && (
                  <Typography className="fs-10 text-danger">
                    {errors.tasks?.at(idx)?.gameModes}
                  </Typography>
                )}
            </BsForm.Group>
          </Col>
          <Col md={4}>
            <Select
              isMulti
              name={`tasks.${idx}.trackingDetails.matchIds`}
              placeholder="Select matches"
              options={matchOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              value={matchOptions?.filter((option) =>
                task?.trackingDetails?.matchIds?.includes(option.value),
              )}
              isDisabled={task.trackingDetails.isAnyMatch}
              menuPlacement="top"
              styles={{
                menu: (base, props) => ({
                  ...base,
                  zIndex: 200,
                }),
              }}
              onChange={(selectedOptions) => {
                const matchIds = selectedOptions
                  ? selectedOptions.map((option) => option.value)
                  : [];
                setFieldValue(
                  `tasks.${idx}.trackingDetails.matchIds`,
                  matchIds,
                );

                setFieldValue(
                  `tasks.${idx}.trackingDetails.totalMatchesCount`,
                  matchIds.length,
                );
              }}
            />
          </Col>
          <Col>
            <BsForm.Check
              type="checkbox"
              label="isAnyMatch"
              name={`tasks.${idx}.trackingDetails.isAnyMatch`}
              checked={task.trackingDetails.isAnyMatch}
              className="pt-2"
              onChange={() =>
                setFieldValue(
                  `tasks.${idx}.trackingDetails.isAnyMatch`,
                  !task.trackingDetails.isAnyMatch,
                )
              }
            />
          </Col>
        </Row>
      )}
    </section>
  );
};

export default TaskFields;

import {
  DateRangeEnum,
  DateRangeEnumLabel,
  DaysToGetEnum,
} from "../common/constants";
export const staticDateOption = [
  {
    key: DateRangeEnum.last7Days,
    label: DateRangeEnumLabel[DateRangeEnum.lastWeek],
    daysToGet: DaysToGetEnum.prev,
    daysNumber: 7,
  },
  {
    key: DateRangeEnum.last15Days,
    label: DateRangeEnumLabel[DateRangeEnum.last15Days],
    daysToGet: DaysToGetEnum.prev,
    daysNumber: 15,
  },
  {
    key: DateRangeEnum.lastMonth,
    label: DateRangeEnumLabel[DateRangeEnum.lastMonth],
    daysToGet: DaysToGetEnum.prev,
    daysNumber: 30,
  },
];

export const initialDateRange = [null, null];
export const initialStaticDateRange = {
  [DateRangeEnum.last7Days]: true,
  [DateRangeEnum.last15Days]: false,
  [DateRangeEnum.lastMonth]: false,
};

export const referralStatsStaticDateRange = {
  ...initialStaticDateRange,
  [DateRangeEnum.last7Days]: false,
};

import API from "../../../api/api";
import { getBase } from "../../../apiconfig";
import { URLS } from "../../../common/url";
import { HttpStatus } from "../../../utils/constants";
import { ERROR_MESSAGE_TYPE } from "../../../utils/error/errorMsg";

// To save the color of the team
export const saveTeamColorORLogoConfig = async (
  payload = {},
  config: any = {},
) => {
  try {
    const { teamId, ...restConfig } = config;
    const url: string = getBase() + "/api" + URLS.TEAM_LIST_URL + `/${teamId}`;
    const data: any = await API.put(url, payload, restConfig);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};

export const fetchTeamLogoPresignedUrl = async (
  fileName: string,
  config = {},
) => {
  try {
    const url: string =
      getBase() +
      "/api" +
      URLS.TEAMLOGO_PRESIGNED_URL.replace("{fileName}", fileName);
    const data: any = await API.get(url, config);
    if (data.status === 200 || data.status === 201) {
      if (data?.data?.data?.error)
        // eslint-disable-next-line no-throw-literal
        throw {
          status: HttpStatus.BAD_REQUEST,
          message:
            data?.data?.data?.error ||
            ERROR_MESSAGE_TYPE[HttpStatus.BAD_REQUEST],
        };
      else return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
};

export async function uploadTeamLogoToPresignedUrl(
  presignUrl: string,
  file: any,
  config = {},
) {
  try {
    const data: any = await API.put(presignUrl, file, config);
    if (data.status === 200 || data.status === 201) {
      return data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        status: data.status,
        message: data?.data?.message || ERROR_MESSAGE_TYPE[data?.status],
      };
    }
  } catch (error) {
    return error;
  }
}

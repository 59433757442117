import React, { useState, useEffect } from "react";
import "./style.scss";
import { fetchUserStats } from "../../service";
import { HttpStatus } from "../../../utils/constants";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import { EMPTY_ERROR, Stats } from "../../../common/constants";
import "./style.scss";

const statDescription = {
  activeUsersCount: "Total Active Users",
  deletedUsersCount: "Number of Users Deleted",
  registeredUsersCount: "Number of Users Registered",
};

export const UserStatComponent = ({ startDate, endDate }) => {
  const [userStats, setUserStats] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });

  useEffect(() => {
    if (startDate && endDate) {
      getUserStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const getUserStats = async () => {
    setUserStats({
      ...userStats,
      isLoading: true,
      error: EMPTY_ERROR,
    });

    const resData: any = await fetchUserStats(startDate, endDate);

    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      const stats = Object.keys(resData?.data?.data).map((key) => {
        return {
          description: statDescription[key],
          value: resData?.data?.data[key],
        };
      });

      setUserStats((pre) => ({
        ...pre,
        isLoading: false,
        data: {
          stats,
        },
      }));
    } else {
      setUserStats({
        ...userStats,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
    }
  };

  const columns = React.useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: "description",
        id: "description",
        header: () => <span>{Stats.description}</span>,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "value",
        id: "value",
        header: () => <span>{Stats.value}</span>,
        cell: (info) => info.getValue(),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <TableWrapper>
        <ReactTable
          isLoading={userStats?.isLoading}
          data={userStats?.data?.stats || []}
          columns={columns}
          hasError={userStats?.error.isError}
          errorMessage={userStats?.error.errorMsg}
        />
      </TableWrapper>
    </>
  );
};

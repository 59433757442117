import { useState, useRef, useEffect } from "react";
import "./style.scss";
import { Button, Image, Form, Card, CardBody } from "react-bootstrap";
import {
  Action,
  EMPTY_ERROR,
  TeamsConfig,
  max_photo_size,
} from "../../../../common/constants";
import NoImage from "../../../../../src/common/assets/NoImage.svg";
import FormControl from "../../../../common/components/FormControl/FormControl";
import ConfirmationModal from "../../../../common/components/Modal/ConfirmationModel";
import {
  fetchTeamLogoPresignedUrl,
  saveTeamColorORLogoConfig,
  uploadTeamLogoToPresignedUrl,
} from "../service";
import { CustomStatusCode, HttpStatus } from "../../../../utils/constants";
import { resetTeamsData } from "../teamColorConfigSlice";
import { useDispatch } from "react-redux";
import ReactToastr from "../../../../common/components/ReactToaster";
import { getColorList } from "../../TeamConfigure/teamConfigHelper";

const initialErrorState = EMPTY_ERROR;
const TeamsColorConfigView = ({ team, handleGoBack, handleReload }) => {
  const dispatch = useDispatch();
  const [color1, setColor1] = useState(team?.teamColors?.[0] || "");
  const [color2, setColor2] = useState(team?.teamColors?.[1] || "");
  const [color3, setColor3] = useState(team?.teamColors?.[2] || "");
  const [shirtNumberColor, setShirtNumberColor] = useState({
    homeShirtNumberColor: team?.shirtNumberColors?.homeShirtNumberColor || "",
    awayShirtNumberColor: team?.shirtNumberColors?.awayShirtNumberColor || "",
    neutralShirtNumberColor:
      team?.shirtNumberColors?.neutralShirtNumberColor || "",
  });
  const [error, setError] = useState(initialErrorState);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState<string>("");
  const [presignUrl, setPresignUrl] = useState<string>("");
  const [image, setImage] = useState(null);
  const [teamLogo, setTeamLogo] = useState(null);
  const [isTeamLogoUpdated, setIsTeamLogoUpdated] = useState(false);
  const [isUploadLogo, setIsUploadLogo] = useState(false);
  const fileInputRef = useRef(null);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [isSaveTeamColor, setIsSaveTeamColor] = useState(false);
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
  });

  const handleClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
    }));

    if (showToaster?.callBack) {
      showToaster.callBack();
    }
  };

  useEffect(() => {
    setImage(team?.logoUrl);
    setTeamLogo(team?.logoUrl);
  }, [team?.logoUrl]);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > max_photo_size) {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: "Please upload an image smaller than 2MB.",
          variant: "danger",
        }));
        return;
      }
      setFile(selectedFile);
      setFileName(selectedFile.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleThumbnailClick = () => {
    fileInputRef.current.click();
  };

  const handleUpload = async () => {
    if (!file) {
      setIsUploadLogo(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "Please select an image",
        variant: "danger",
      }));
      return;
    }
    try {
      const resData = await uploadTeamLogoToPresignedUrl(presignUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      if (resData?.status === HttpStatus.REQUEST_SUCCEEDED) {
        setTeamLogo(presignUrl);
        setIsTeamLogoUpdated(true);
        setIsUploadLogo(false);
      } else {
        setIsUploadLogo(false);
        setImage(teamLogo);
        if (resData?.status !== CustomStatusCode.sessionExpire)
          setShowToaster((prev) => ({
            ...prev,
            show: true,
            message: `${
              resData?.message || "Something went wrong, please try again"
            }`,
            variant: "danger",
          }));
      }
    } catch (error) {
      setIsUploadLogo(false);
      setImage(teamLogo);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "Error uploading team logo",
        variant: "danger",
      }));
    }
  };

  const getPresignedUrl = async () => {
    setIsUploadLogo(true);
    const resData: any = await fetchTeamLogoPresignedUrl(fileName);
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      if (resData?.data?.data) {
        setPresignUrl(resData?.data?.data?.presignUrl);
      }
    } else {
      setIsUploadLogo(false);
      setImage(teamLogo);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
  };

  useEffect(() => {
    if (presignUrl) {
      handleUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presignUrl]);

  useEffect(() => {
    if (fileName) {
      getPresignedUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  const handleTeamColorSave = () => {
    if (!color1) {
      setError({ isError: true, errorMsg: "Please choose some color" });
    } else {
      setShowConfirmationModal(true);
    }
  };

  const saveTeamColorConfiguration = async () => {
    setIsSaveTeamColor(true);
    const payload = {
      teamColors: getColorList(color1, color2, color3),
      shirtNumberColors: shirtNumberColor,
    };
    if (isTeamLogoUpdated) {
      payload["logoUrl"] = teamLogo;
    }
    const resData: any = await saveTeamColorORLogoConfig(payload, {
      teamId: team?.id,
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setIsSaveTeamColor(false);
      setIsTeamLogoUpdated(false);
      dispatch(resetTeamsData());
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: resData?.message || "Successfully saving the team colors",
        variant: "success",
        callBack: () => {
          handleGoBack();
          handleReload();
        },
      }));
    } else {
      setIsSaveTeamColor(false);
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message || "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
    }
  };

  return (
    <>
      <div className="team-color-config-view-wrapper">
        <Button variant="link" onClick={() => handleGoBack()}>
          {Action.goBack}
        </Button>
        <Card>
          <CardBody>
            <div className="d-flex">
              <label className="d-block fw-light fs-13 width-25">
                {TeamsConfig.teams.teamName}
              </label>
              <span className="d-block  fw-medium text-capitalize">
                {team?.name}
              </span>
            </div>
            <div className="mt-3 d-flex">
              <label className="d-block fw-light fs-13 width-25">
                {TeamsConfig.teams.teamLogo}
              </label>
              <div>
                <div className="team-logo-wrapper">
                  {image ? (
                    <Image
                      src={image}
                      className="team-logo-style"
                      alt="team logo"
                    />
                  ) : (
                    <Image
                      src={NoImage}
                      width={100}
                      height={100}
                      alt="logo"
                      onClick={handleThumbnailClick}
                      thumbnail
                    />
                  )}
                  <Button
                    className="logo-upload-button"
                    onClick={handleThumbnailClick}
                    disabled={isUploadLogo}
                    variant="link"
                  >
                    {image
                      ? isUploadLogo
                        ? Action.updating
                        : Action.update
                      : isUploadLogo
                      ? Action.uploading
                      : Action.upload}
                  </Button>
                </div>
                <Form.Group style={{ display: "none" }}>
                  <Form.Label>Upload Image</Form.Label>
                  <Form.Control
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="mt-3 d-flex">
              <label className="d-block fw-light fs-13 width-25">
                {TeamsConfig.teams.code}
              </label>
              <span className="d-block  fw-medium text-capitalize">
                {team?.shortCode}
              </span>
            </div>
            <div className="mt-3 d-flex">
              <label className="d-block fw-light fs-13 width-25">
                {TeamsConfig.teams.teamColor}
                <span className="star-style">*</span>
              </label>
              <span className="d-flex color-span-wrapper span-gap">
                <FormControl
                  type="text"
                  className="input-box-styling"
                  value={color1}
                  onChange={(e) => {
                    setColor1(e.target.value);
                    setError(EMPTY_ERROR);
                  }}
                  isRequired={true}
                  isError={error?.isError}
                  error={error?.errorMsg}
                />
                <Form.Control
                  type="color"
                  id="colorInut1"
                  value={color1}
                  title="Choose team color"
                  onChange={(e) => {
                    setColor1(e.target.value);
                    setError(EMPTY_ERROR);
                  }}
                />
              </span>
              <span className="d-flex color-span-wrapper span-gap">
                <FormControl
                  type="text"
                  className="input-box-styling"
                  value={color2}
                  onChange={(e) => {
                    setColor2(e.target.value);
                  }}
                />
                <Form.Control
                  type="color"
                  id="colorInut2"
                  value={color2}
                  title="Choose team color"
                  onChange={(e) => {
                    setColor2(e.target.value);
                  }}
                />
              </span>
              <span className="d-flex color-span-wrapper">
                <FormControl
                  type="text"
                  className="input-box-styling"
                  value={color3}
                  onChange={(e) => {
                    setColor3(e.target.value);
                  }}
                />
                <Form.Control
                  type="color"
                  id="colorInut3"
                  value={color3}
                  title="Choose team color"
                  onChange={(e) => {
                    setColor3(e.target.value);
                  }}
                />
              </span>
            </div>
            <div className="mt-3 d-flex">
              <label className="d-block fw-light fs-13 width-25">
                {TeamsConfig.teams.teamShirtNumberColor}
                <span className="star-style">*</span>
                <span className="">(Home/Away/Neutral)</span>
              </label>
              <span className="d-flex color-span-wrapper span-gap">
                <FormControl
                  type="text"
                  className="input-box-styling"
                  value={shirtNumberColor.homeShirtNumberColor}
                  onChange={(e) => {
                    setShirtNumberColor({
                      ...shirtNumberColor,
                      homeShirtNumberColor: e.target.value,
                    });
                    setError(EMPTY_ERROR);
                  }}
                  isRequired={true}
                  isError={error?.isError}
                  error={error?.errorMsg}
                />
                <Form.Control
                  type="color"
                  id="homeShirtNumberColor"
                  value={shirtNumberColor.homeShirtNumberColor}
                  title="Choose team jersey number color"
                  onChange={(e) => {
                    setShirtNumberColor({
                      ...shirtNumberColor,
                      homeShirtNumberColor: e.target.value,
                    });
                    setError(EMPTY_ERROR);
                  }}
                />
              </span>
              <span className="d-flex color-span-wrapper span-gap">
                <FormControl
                  type="text"
                  className="input-box-styling"
                  value={shirtNumberColor.awayShirtNumberColor}
                  onChange={(e) => {
                    setShirtNumberColor({
                      ...shirtNumberColor,
                      awayShirtNumberColor: e.target.value,
                    });
                  }}
                />
                <Form.Control
                  type="color"
                  id="awayShirtNumberColor"
                  value={shirtNumberColor.awayShirtNumberColor}
                  title="Choose team jersey color"
                  onChange={(e) => {
                    setShirtNumberColor({
                      ...shirtNumberColor,
                      awayShirtNumberColor: e.target.value,
                    });
                  }}
                />
              </span>
              <span className="d-flex color-span-wrapper">
                <FormControl
                  type="text"
                  className="input-box-styling"
                  value={shirtNumberColor.neutralShirtNumberColor}
                  onChange={(e) => {
                    setShirtNumberColor({
                      ...shirtNumberColor,
                      neutralShirtNumberColor: e.target.value,
                    });
                  }}
                />
                <Form.Control
                  type="color"
                  id="neutralShirtNumberColor"
                  value={shirtNumberColor.neutralShirtNumberColor}
                  title="Choose team jersey color"
                  onChange={(e) => {
                    setShirtNumberColor({
                      ...shirtNumberColor,
                      neutralShirtNumberColor: e.target.value,
                    });
                  }}
                />
              </span>
            </div>
            <div className="team-color-save-button">
              <Button
                variant="primary"
                onClick={() =>
                  (!isSaveTeamColor || !isUploadLogo) && handleTeamColorSave()
                }
                disabled={isSaveTeamColor || isUploadLogo}
              >
                {isSaveTeamColor ? Action.saving : Action.save}
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
      <ConfirmationModal
        variant="alert"
        show={showConfirmationModal}
        title={`Are you sure, you want to save the details?`}
        handleClick={() => {
          setShowConfirmationModal(false);
          saveTeamColorConfiguration();
        }}
        handleClose={() => setShowConfirmationModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowConfirmationModal(false)}
        btnText="Ok"
      />
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleClose}
        position="top-center"
        autohide
      />
    </>
  );
};

export default TeamsColorConfigView;
